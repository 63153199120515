import { observable, computed, action, toJS, decorate } from 'mobx'
import { equals, isNil } from 'ramda'

import { uid } from 'utils'
import { roundDecimal } from 'utils/currency'
import CartStore from 'stores/Mission/domain/CartStore'

class RelatedCost {
  cde = null
  type = null
  description = ''
  priceWithoutVAT = 0
  priceWithVAT = 0
  vat = 20
  darvaLookupValue = null
  supportingDocuments = []
  originalData = null
  packageType = 'relatedCost'
  involvedParty = null
  ren = false
  selfRepair = true

  id = uid(8)
  label = ''

  constructor(data) {
    this.id = data.id || uid(8)
    this.label = data.label || ''
    this.cde = data.cde || null
    this.type = data.type || null
    this.description = data.description || ''
    this.priceWithoutVAT = isNil(data.priceWithoutVAT) ? 0 : data.priceWithoutVAT
    this.priceWithVAT = isNil(data.priceWithVAT) ? 0 : data.priceWithVAT
    this.vat = isNil(data.vat) ? (CartStore.isTaxSystemHT ? 0 : 20) : data.vat
    this.darvaLookupValue = data.darvaLookupValue || null
    this.supportingDocuments = data.supportingDocuments || []
    this.packageType = data.packageType || 'relatedCost'
    this.involvedParty =
      data.involvedParty === 'notVentilated' || isNil(data.involvedParty)
        ? null
        : data.involvedParty
    this.originalData = this.asJson
    this.ren = isNil(data.ren) ? false : data.ren
    this.selfRepair = isNil(data.selfRepair) ? true : data.selfRepair
  }

  get asJson() {
    return {
      id: this.id,
      cde: this.cde,
      label: this.label,
      type: this.type,
      description: this.description,
      vat: this.vat,
      priceWithoutVAT: this.priceWithoutVAT,
      priceWithVAT: this.priceWithVAT,
      darvaLookupValue: this.darvaLookupValue,
      supportingDocuments: this.supportingDocuments.map(sd => sd),
      packageType: this.packageType,
      involvedParty: this.involvedParty,
      ren: this.ren,
      selfRepair: this.selfRepair,
    }
  }

  get changed() {
    if (this.originalData.supportingDocuments.length !== this.supportingDocuments.length) {
      return true
    }
    return !equals(toJS(this.originalData), this.asJson)
  }

  get totalVAT() {
    return this.priceWithoutVAT * (this.vat / 100)
  }

  get validRelatedCost() {
    const descriptionCondition = this.description.length > 0
    const labelCondition = this.label.length > 0

    if (this.packageType === 'relatedCost')
      return this.type !== null && descriptionCondition && labelCondition

    return this.cde !== null && descriptionCondition && labelCondition
  }

  get validRelatedCostEE() {
    const descriptionCondition = this.descriptionRequired ? this.description.length > 0 : true
    return this.cde !== null && descriptionCondition
  }

  get descriptionRequired() {
    if (isNil(this.cde)) return false
    else return this.cde === '32' || this.cde === '33'
  }

  addSupportingDocument(id) {
    this.supportingDocuments.push(id)
  }

  setOriginalData() {
    this.originalData = this.asJson
  }

  setProperty(key, value) {
    if (key === 'priceWithoutVAT') {
      this.priceWithVAT = roundDecimal(value * (1 + this.vat / 100))
    } else if (key === 'priceWithVAT') {
      this.priceWithoutVAT = roundDecimal(value / (1 + this.vat / 100))
    } else if (key === 'vat') {
      this.priceWithVAT = roundDecimal(this.priceWithoutVAT * (1 + value / 100))
    } else if (key === 'selfRepair' && value === true) {
      this.ren = false
    } else if (key === 'ren' && value === true) {
      this.selfRepair = false
    }

    if (key === 'involvedParty' && value === '') {
      value = null
    }
    this[key] = value
  }

  setCde({ value, options }) {
    const relatedCost = options.find(type => type.value === value)

    if (!relatedCost) {
      this.cde = value
    } else if (
      this.packageType === 'relatedCost' ||
      this.packageType === 'overflow' ||
      this.packageType === 'noGuarantee'
    ) {
      this.cde = value
      this.label = relatedCost.label
      this.description = relatedCost.description
      this.type = relatedCost.type
      this.darvaLookupValue = relatedCost.darvaLookupValue
      this.priceWithoutVAT =
        relatedCost.price === null || relatedCost.price === undefined ? 0 : relatedCost.price
    } else {
      this.cde = value
      this.label = relatedCost.label
      console.warn('RC : package type is unknown ', this.packageType)
    }
  }
}

const DecoratedRelatedCost = decorate(RelatedCost, {
  cde: observable,
  type: observable,
  description: observable,
  priceWithoutVAT: observable,
  priceWithVAT: observable,
  vat: observable,
  darvaLookupValue: observable,
  supportingDocuments: observable,
  originalData: observable,
  involvedParty: observable,
  ren: observable,
  selfRepair: observable,

  asJson: computed,
  changed: computed,
  totalVAT: computed,
  validRelatedCost: computed,
  descriptionRequired: computed,
  validRelatedCostEE: computed,

  addSupportingDocument: action,
  setOriginalData: action,
  setProperty: action.bound,
  setCde: action,
})

export default DecoratedRelatedCost
