import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'

import MissionButtons from 'pages/Mission/components/MissionButtons'
import SmartLink from 'components/SmartLink/SmartLink'
// import ProtectedLink from 'components/ProtectedLink/ProtectedLink'
import GenerateReportLink from './GenerateReportLink'

const MissionDesktop = ({
  MissionStore: { policy, isWorkCFA, claim, isHorsDarva, isIRSI, isASP, pvRequired },
  MessagingStore: { notifications },
  PrejudiceStore: { payloads, ready },
  UserStore: { customer },
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const hasExpertiseSimplify = customer.hasContract('ES')

  return (
    <Fragment>
      <div className="nav flex-column nav-pills">
        <NavLink className="nav-link" activeClassName="active" to={`/mission/${id}/information`}>
          {t('mission.sideNav.information')}
        </NavLink>
        {claim && !isWorkCFA ? (
          <NavLink className="nav-link" activeClassName="active" to={`/mission/${id}/claim`}>
            {t('mission.sideNav.claim')}
          </NavLink>
        ) : null}
        {policy && !isWorkCFA ? (
          <NavLink className="nav-link" activeClassName="active" to={`/mission/${id}/policy`}>
            {t('mission.sideNav.policy')}
          </NavLink>
        ) : null}
      </div>

      <h5 className="mt-4 mb-4">{t('mission.sideNav.titleAnnexe')}</h5>

      <div className="nav flex-column nav-pills">
        {!hasExpertiseSimplify && (
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/mission/${id}/partyInvolved`}
          >
            {t('mission.sideNav.partyInvolved')}
          </NavLink>
        )}
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/mission/${id}/supportingDocuments`}
        >
          {t('mission.sideNav.supportingDocument')}
        </NavLink>
      </div>

      {!isHorsDarva && (
        <Fragment>
          <h5 className="mt-4 mb-4">{t('mission.sideNav.titleEvaluation')}</h5>

          <div className="nav flex-column nav-pills">
            {!hasExpertiseSimplify && (
              <SmartLink page="riskConformity">{t('mission.sideNav.riskConformity')}</SmartLink>
            )}
            {!hasExpertiseSimplify && (
              <SmartLink page="causesAndCircumstances">
                {' '}
                {t('mission.sideNav.causesAndCircumstances')}
              </SmartLink>
            )}
            {!hasExpertiseSimplify && (
              <SmartLink page="damageDescription">
                {t('mission.sideNav.damageDescription')}
              </SmartLink>
            )}
            {!hasExpertiseSimplify && isIRSI && (
              <SmartLink page="irsi">{t('mission.sideNav.irsi')}</SmartLink>
            )}
            <SmartLink page="calculation">{t('mission.sideNav.calculation')}</SmartLink>
            {!hasExpertiseSimplify && (
              <SmartLink page="ventilation">{t('mission.sideNav.ventilationREN')}</SmartLink>
            )}
            {!hasExpertiseSimplify && ready && !!payloads.length && (
              <SmartLink page="prejudice">{t('mission.sideNav.prejudice')}</SmartLink>
            )}
            <SmartLink page="conclusion">{t('mission.sideNav.conclusion')}</SmartLink>
            {!hasExpertiseSimplify && pvRequired && (
              <SmartLink page="pv">{t('mission.sideNav.pv')}</SmartLink>
            )}
            <SmartLink page="rates">{t('mission.sideNav.rates')}</SmartLink>
          </div>

          <h5 className="mt-4 mb-4">{t('mission.sideNav.titleReport')}</h5>

          <div className="nav flex-column nav-pills">
            <GenerateReportLink />
          </div>
        </Fragment>
      )}

      <h5 className="mt-4 mb-4">{t('mission.sideNav.titleCommunication')}</h5>

      <div className="nav flex-column nav-pills ">
        <div className="container-notif">
          {!isWorkCFA && (
            <NavLink className="nav-link" activeClassName="active" to={`/mission/${id}/messaging`}>
              {t('messaging.title')}

              {notifications > 0 && (
                <span className="badge badge-danger ml-1">{notifications}</span>
              )}
            </NavLink>
          )}

          <NavLink className="nav-link" activeClassName="active" to={`/mission/${id}/events`}>
            {t('events.title')}
          </NavLink>
        </div>
      </div>

      <div className="mt-4 justify-content-between">
        <MissionButtons />
      </div>
    </Fragment>
  )
}

export default compose(
  inject('MissionStore', 'MessagingStore', 'PrejudiceStore', 'UserStore'),
  observer,
)(MissionDesktop)
