import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import SupportingDocumentsCtrl, {
  SupportingDocTypes,
} from 'stores/Common/view/SupportingDocumentsCtrl'
import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import MissionStore from 'stores/Mission/domain/MissionStore'
import ClaimStore from 'stores/Claim/domain/ClaimStore'
import DrawCtrl from 'stores/Mission/view/DrawCtrl'
import Tooltip from 'components/Tooltip/Tooltip'
import Button from 'components/Button/Button'
import UserStore from 'stores/Common/domain/UserStore'
import ReportCtrl from 'stores/Mission/view/ReportCtrl'

const UploadButton = observer(({ t }) => {
  if (MissionStore.isMissionClosed || ClaimStore.isClaimClosed) return null

  return (
    <div>
      <Tooltip className="tooltip-action-bar" text={t('mission.supportingDocuments.sendPicture')}>
        <button
          className={classNames('btn btn-outline-primary add-sd')}
          onClick={() => {
            SupportingDocumentsCtrl.dropZoneFiltered
              ? SupportingDocumentsCtrl.setDropZoneFiltered(false)
              : SupportingDocumentsCtrl.setDropZoneFiltered(true)
            SupportingDocumentsCtrl.resetSdSelected()
          }}
        >
          {SupportingDocumentsCtrl.dropZoneFiltered ? (
            <i className="fa fa-th" />
          ) : (
            <i className="fa fa-upload" />
          )}
        </button>
      </Tooltip>
    </div>
  )
})

class ActionBar extends React.Component {
  setDeleteModal = () => {
    SupportingDocumentsCtrl.addSelectedSdAll()
    SupportingDocumentsCtrl.setDeleteModal()
  }
  toggleDrawModal = () => DrawCtrl.toggleDrawModal()
  toggleSelectAllToReport = () => {
    if (SupportingDocumentsCtrl.ctrl.isSdSelectEmpty) {
      SupportingDocumentsCtrl.ctrl.addAllToReport(SupportingDocumentStore.sdFiltered)
    } else {
      SupportingDocumentsCtrl.ctrl.removeAllToTheReport()
    }
  }
  toggleModal = () => ReportCtrl.toggleModal()

  render() {
    const { t } = this.props

    if (MissionStore.isMissionClosed || ClaimStore.isClaimClosed) return null

    return (
      <li className="action-bar">
        {SupportingDocumentsCtrl.sdCategory === SupportingDocTypes.SCHEMA && (
          <div>
            <Tooltip
              className="tooltip-action-bar"
              text={t('mission.supportingDocuments.drawSchema')}
            >
              <button
                className={classNames('btn btn-outline-primary draw-schema')}
                onClick={this.toggleDrawModal}
              >
                <i className="fa fa-pencil" />
              </button>
            </Tooltip>
          </div>
        )}
        <div>
          {SupportingDocumentsCtrl.isCreateMissionSelector === false && (
            <Tooltip
              className="tooltip-action-bar"
              text={
                SupportingDocumentsCtrl.ctrl.isSdSelectEmpty
                  ? t('mission.supportingDocuments.addAllToReport')
                  : t('mission.supportingDocuments.removeAllFromReport')
              }
            >
              <Button
                disabled={SupportingDocumentStore.sdFiltered.length === 0}
                className="btn btn-outline-primary all-to-report"
                onClick={this.toggleSelectAllToReport}
              >
                {SupportingDocumentsCtrl.ctrl.sdAddToReportLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <i className="fa fa-file-text-o" />
                )}
              </Button>
            </Tooltip>
          )}
        </div>
        <div>
          {SupportingDocumentsCtrl.isCreateMissionSelector === false && (
            <Tooltip
              className="tooltip-action-bar"
              text={t('mission.supportingDocuments.deleteAll')}
            >
              <Button
                className="btn btn-outline-primary delete-sd"
                disabled={SupportingDocumentStore.sdFiltered.length === 0}
                onClick={this.setDeleteModal}
              >
                <i className="fa fa-trash" />
              </Button>
            </Tooltip>
          )}
        </div>
        {SupportingDocumentsCtrl.sdCategory !== SupportingDocTypes.INVOICE &&
          SupportingDocumentsCtrl.sdCategory !== SupportingDocTypes.SCHEMA && (
            <UploadButton t={t} />
          )}
        <div>
          {UserStore.hasContract('FWSD') && (
            <Tooltip className="tooltip-action-bar" text={t('mission.report.sendEmail')}>
              <Button className="btn btn-outline-primary delete-sd" onClick={this.toggleModal}>
                <i className="fa fa-share" />
              </Button>
            </Tooltip>
          )}
        </div>
      </li>
    )
  }
}

export default withTranslation()(observer(ActionBar))
