import { observable, computed, action, decorate } from 'mobx'

import { concatNames } from 'utils'
import PartyInvolved from 'stores/Common/domain/PartyInvolved'
import { isNil } from 'ramda'

class PartyInvolvedStore {
  partyInvolved = []

  get attendancePartyForCreateMission() {
    const presents = []
    this.partyInvolved.forEach(party =>
      party.selectedCreateMission ? presents.push(party.id) : null,
    )
    return presents
  }

  get attendanceParty() {
    const presents = []
    this.partyInvolved.forEach(party => (party.attendance ? presents.push(party.id) : null))
    return presents
  }

  get formatPartyInvolved() {
    return this.partyInvolved.map(party => ({
      label:
        party.type === 'personal'
          ? concatNames(party.lastName, party.firstName)
          : party.companyName,
      value: party.id,
      role: party.involvedPartyResponsabilityKey,
    }))
  }

  typePartyInvolved = id => {
    const party = this.partyInvolved.find(party => party.id === id)
    return party !== undefined ? party.type : null
  }

  get asSelectOptions() {
    return this.partyInvolved.map(data => data.asSelectOption)
  }

  loadData(partyInvolved) {
    this.partyInvolved = partyInvolved.map(data => new PartyInvolved(data))
  }

  addParty(newParty) {
    const party = this.partyInvolved.find(party => party.id === newParty.id)
    if (!party) {
      this.partyInvolved.push(newParty)
    }
  }

  deleteParty(id) {
    this.partyInvolved = this.partyInvolved.filter(partyInvolved => partyInvolved.id !== id)
  }

  clearSelectedCreateMission() {
    this.partyInvolved.forEach(party => {
      party.selectedCreateMission = false
    })
  }

  findName = involvedPartyId => {
    const IP = this.partyInvolved.find(IP => IP.id === involvedPartyId)
    if (IP) {
      if (IP.type === 'business') return IP.companyName
      return concatNames(IP.lastName, IP.firstName)
    }
    return ''
  }

  findEmail = involvedPartyId => {
    const IP = this.partyInvolved.find(IP => IP.id === involvedPartyId)
    if (IP) {
      if (IP.type === 'business') return IP.companyEmailAddress
      return IP.emailAddress
    }
    return null
  }

  findEmailPrioritise = involvedPartyId => {
    const IP = this.partyInvolved.find(IP => IP.id === involvedPartyId)
    if (IP) {
      if (!isNil(IP.emailAddress)) return IP.emailAddress
      if (!isNil(IP.companyEmailAddress)) return IP.companyEmailAddress
    }
    return null
  }

  findMobilePhone = involvedPartyId => {
    const IP = this.partyInvolved.find(IP => IP.id === involvedPartyId)
    if (IP) {
      if (!isNil(IP.mobilePhone)) return IP.mobilePhone
      if (!isNil(IP.phoneNumber)) return IP.phoneNumber
    }
    return null
  }
}

const DecoratedPartyInvolvedStore = decorate(PartyInvolvedStore, {
  partyInvolved: observable,

  clearSelectedCreateMission: action,
  deleteParty: action,
  addParty: action,
  loadData: action,

  formatPartyInvolved: computed,
  attendanceParty: computed,
  attendancePartyForCreateMission: computed,
  asSelectOptions: computed,
})

export default new DecoratedPartyInvolvedStore()
