import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { Prompt } from 'react-router-dom'
import { compose } from 'recompose'
import { useParams } from 'react-router-dom'

import PartyModal from 'pages/PartyInvolved/PartyModal'
import Button from 'components/Button/Button'
import FormFields from './FormFields'
import Accordion from './Accordion'

const Form = ({
  t,
  MissionStore: { isMissionClosed },
  ManagerClaimStore: { isClaimClosed },
  formObj,
  formObj: { save, saving, saved, withAccordion, changed },
  saveService,
  nextStep,
  noSubmitBtn,
  noPrompt,
  focus = null,
  additionalSaveCondition,
  forceEnable,
  isValid,
  goToNextStepAfterSave = false,
  isCreationForm = false,
  saveLabel = '',
}) => {
  const onSave = async e => {
    if (e) e.preventDefault()
    try {
      await save(saveService)
      if (goToNextStepAfterSave) {
        nextStep()
      }
    } catch (error) {
      console.warn(error)
    }
  }
  const { type } = useParams()

  const isClosed = isCreationForm ? false : type === 'mission' ? isMissionClosed : isClaimClosed

  return (
    <Fragment>
      <PartyModal />
      <div className="px-2">
        {!noPrompt && (
          <Prompt when={changed || additionalSaveCondition} message={t('common.quitWithoutSave')} />
        )}

        {withAccordion ? (
          <Accordion formObj={formObj} focus={focus} />
        ) : (
          <FormFields formObj={formObj} focus={focus} />
        )}

        {!isClosed && !noSubmitBtn && (
          <div className="mt-3 d-flex justify-content-end">
            <Button
              className="btn btn-primary"
              loading={saving}
              onClick={onSave}
              disabled={
                forceEnable ? false : (!changed && !additionalSaveCondition) || !isValid || saving
              }
            >
              {!saveLabel ? t('common.submit') : saveLabel}
            </Button>

            {nextStep && saved && (
              <Fragment>
                <button className="next-step" onFocus={nextStep} />
                <div className="info-tab">{t('form.pressTab')}</div>
              </Fragment>
            )}
          </div>
        )}
      </div>
    </Fragment>
  )
}

Form.defaultProps = {
  nextStep: null,
  noSubmitBtn: false,
  noPrompt: false,
  additionalSaveCondition: false,
  forceEnable: false,
  isValid: true,
}

export default compose(
  inject('MissionStore', 'ManagerClaimStore'),
  withTranslation(),
  observer,
)(Form)
