import { observable, action, computed, runInAction, toJS, decorate } from 'mobx'
import { uniq, isNil } from 'ramda'

import MissionStore from 'stores/Mission/domain/MissionStore'
import { fetchEvents } from 'services/events'

class EventCtrl {
  events = []
  loading = false
  filters = {
    type: null,
    level: null,
    user: null,
    category: null,
  }

  get filterType() {
    return uniq(
      this.events.map(event => ({
        label: MissionStore.id === event.wan ? 'CFA' : 'CFI',
        value: MissionStore.id === event.wan ? 'CFA' : 'CFI',
      })),
    )
  }

  get filterLevel() {
    return uniq(
      this.events.map(event => ({
        label: event.type.display_level.toString(),
        value: event.type.display_level.toString(),
      })),
    )
  }

  get filterUser() {
    const users = uniq(
      this.events.map(event => ({
        label: `${event.user.firstName} ${event.user.lastName}`,
        value: event.user.email,
      })),
    )
    return users.filter(user => !isNil(user.value))
  }

  get filterCategory() {
    return uniq(
      this.events.map(event => ({
        label: event.type.category.value,
        value: event.type.category.value,
      })),
    )
  }

  get eventsFiltered() {
    let events = toJS(this.events)
    const filterKeys = [
      {
        key: 'type',
        condition: event => (MissionStore.id === event.wan ? 'CFA' : 'CFI') === this.filters.type,
      },
      {
        key: 'level',
        condition: event => event.type.display_level.toString() === this.filters.level,
      },
      { key: 'user', condition: event => event.user.email === this.filters.user },
      { key: 'category', condition: event => event.type.category.value === this.filters.category },
    ]

    filterKeys.forEach(filter => {
      if (this.filters[filter.key] !== null) events = events.filter(filter.condition.bind(this))
    })
    return events
  }

  async loadData(wan, isCfmExpertMission = false) {
    this.loading = true
    try {
      const res = await fetchEvents(wan, isCfmExpertMission)
      runInAction(() => {
        this.events = res.map(event => event.attributes)
        this.loading = false
      })
    } catch (err) {
      console.log(err)
    }
  }

  onChangeFilters({ name, value }) {
    this.filters[name] = value
  }

  resetFilters() {
    this.filters = {
      type: null,
      level: null,
      user: null,
      category: null,
    }
  }
}

const DecoratedEventCtrl = decorate(EventCtrl, {
  events: observable,
  loading: observable,
  filters: observable,

  loadData: action.bound,
  onChangeFilters: action.bound,
  resetFilters: action.bound,

  filterType: computed,
  filterLevel: computed,
  filterUser: computed,
  filterCategory: computed,
  eventsFiltered: computed,
})

export default new DecoratedEventCtrl()
