import { observable, computed, action, reaction, toJS, decorate } from 'mobx'
import { equals, isNil } from 'ramda'

import Item from 'stores/Mission/domain/Item'
import CartStore from 'stores/Mission/domain/CartStore'
import { roundDecimal } from 'utils/currency'
import { uid } from 'utils'

const Localisations = Object.freeze(['CEILING', 'FLOOR', 'WALL'])

class Package {
  label = ''
  description = ''
  supportingDocuments = []
  qualityType = 'medium'
  priceType = 'catalog'
  fromList = 'listSearch'
  packageType = null
  roomType = null
  localisation = null
  selfRepair = false
  age = 0
  correction = 0
  note = ''
  overridePrice = null
  areaSquare = 1
  overrideAreaSquare = null
  quantity = 1
  vat = 10
  obsolescenceReturn = 25
  ren = true
  companyName = ''
  originalData = null
  location = 'regular'

  cde = null
  items = []
  lifetime = null
  localisations = []
  priceListPrice = null
  priceList = null
  priceListLabel = null
  unit = null
  difficulty = 1
  minPrice = -1
  score = -1
  id = null
  _id = null
  changeQualityTypeHandler = null
  changeSelfRepairHandler = null
  changeOverridePriceHandler = null
  changeOverrideAreaSquareHandler = null
  involvedParty = null
  groupId = null

  get originalTotalPriceCatalog() {
    if (this.overridePrice !== null) return this.overridePrice
    if (
      this.priceType !== 'catalog' &&
      this.priceType !== 'custom' &&
      this.packageType !== 'DAT_LEAK'
    )
      return this.priceListPrice * (this.area ? this.area : 1)

    let total = 0
    this.items.forEach(item => (total += item.piActive ? item.totalPriceCatalog : 0))
    if (total < this.minPrice) return this.minPrice

    return total
  }

  get originalTotalPriceSelfRepair() {
    if (this.overridePrice !== null) return this.overridePrice
    if (this.priceType !== 'catalog' && this.priceType !== 'custom') {
      const configurationPrice = CartStore.configuration.finalPriceListSelfRepairRate

      return this.priceListPrice * (this.area ? this.area : 1) * configurationPrice
    }

    let total = 0
    this.items.forEach(item => (total += item.piActive ? item.totalPriceSelfRepair : 0))
    return total
  }

  get originalTotalPrice() {
    return this.selfRepair ? this.originalTotalPriceSelfRepair : this.originalTotalPriceCatalog
  }

  get quantityOriginalTotalPriceCatalog() {
    if (this.quantity === '') return this.originalTotalPriceCatalog
    return roundDecimal(this.quantity * this.originalTotalPriceCatalog)
  }

  get quantityOriginalTotalPriceSelfRepair() {
    if (this.quantity === '') return this.originalTotalPriceSelfRepair
    return roundDecimal(this.quantity * this.originalTotalPriceSelfRepair)
  }

  get quantityOriginalTotalPrice() {
    return this.selfRepair
      ? this.quantityOriginalTotalPriceSelfRepair
      : this.quantityOriginalTotalPriceCatalog
  }

  get quantityTotalCatalog() {
    if (this.quantity === '') return this.originalTotalPriceCatalog
    return roundDecimal(this.quantity * this.originalTotalPriceCatalog)
  }

  get quantityTotalSelfRepair() {
    if (this.quantity === '') return this.originalTotalPriceSelfRepair
    return roundDecimal(this.quantity * this.originalTotalPriceSelfRepair)
  }

  get quantityTotal() {
    return this.selfRepair ? this.quantityTotalSelfRepair : this.quantityTotalCatalog
  }

  get obsolescence() {
    if (this.age === 0) return this.correction

    const total = (this.age / this.lifetime) * 80
    if (total >= 80) return 80
    return roundDecimal(total)
  }

  get totalPriceCatalogObsolescenceWithoutVAT() {
    return roundDecimal((this.quantityTotalCatalog * this.obsolescence) / 100)
  }

  get totalPriceCatalogObsolescence() {
    return roundDecimal((this.totalPriceCatalogWithVAT * this.obsolescence) / 100)
  }

  get totalPriceSelfRepairObsolescenceWithoutVAT() {
    return roundDecimal((this.quantityTotalSelfRepair * this.obsolescence) / 100)
  }

  get totalSelfRepairObsolescence() {
    return roundDecimal((this.totalPriceSelfRepairWithVAT * this.obsolescence) / 100)
  }

  get totalObsolescence() {
    return roundDecimal((this.totalPriceVAT * this.obsolescence) / 100)
  }

  get totalObsolescenceWithoutVAT() {
    return roundDecimal((this.totalPriceWithoutVAT * this.obsolescence) / 100)
  }

  get totalCatalogObsolescenceReturn() {
    const totalPrice = this.totalPriceCatalogWithVAT
    if (this.obsolescenceReturn === 0) return 0
    const totalOR = roundDecimal((this.obsolescenceReturn / 100) * totalPrice)

    return totalOR > this.totalPriceCatalogObsolescence
      ? this.totalPriceCatalogObsolescence
      : totalOR
  }

  get totalCatalogORWithoutVAT() {
    const totalPrice = this.quantityTotalCatalog
    if (this.obsolescenceReturn === 0) return 0
    const totalOR = roundDecimal((this.obsolescenceReturn / 100) * totalPrice)

    return totalOR > this.totalObsolescenceWithoutVAT ? this.totalObsolescenceWithoutVAT : totalOR
  }

  get totalSelfRepairObsolescenceReturn() {
    const totalPrice = this.totalPriceSelfRepairWithVAT
    if (this.obsolescenceReturn === 0) return 0
    const totalOR = roundDecimal((this.obsolescenceReturn / 100) * totalPrice)

    return totalOR > this.totalSelfRepairObsolescence ? this.totalSelfRepairObsolescence : totalOR
  }

  get totalSelfRepairORWithoutVAT() {
    const totalPrice = this.quantityTotalSelfRepair
    if (this.obsolescenceReturn === 0) return 0
    const totalOR = roundDecimal((this.obsolescenceReturn / 100) * totalPrice)

    return totalOR > this.totalObsolescenceWithoutVAT ? this.totalObsolescenceWithoutVAT : totalOR
  }

  get totalObsolescenceReturn() {
    const totalPrice = this.totalPriceVAT
    if (this.obsolescenceReturn === 0) return 0
    const totalOR = roundDecimal((this.obsolescenceReturn / 100) * totalPrice)

    return totalOR > this.totalObsolescence ? this.totalObsolescence : totalOR
  }

  get totalCatalogVAT() {
    if (this.vat === 0) return 0
    return roundDecimal(this.quantityTotalCatalog * (this.vat / 100))
  }

  get totalSelfRepairVAT() {
    return 0
  }

  get totalVAT() {
    return this.selfRepair ? this.totalSelfRepairVAT : this.totalCatalogVAT
  }

  get totalPriceCatalogWithVAT() {
    return roundDecimal(this.totalCatalogVAT + this.quantityTotalCatalog)
  }

  get totalPriceSelfRepairWithVAT() {
    return roundDecimal(this.totalSelfRepairVAT + this.quantityTotalSelfRepair)
  }

  get totalPriceVAT() {
    const totalPrice = this.selfRepair ? this.quantityTotalSelfRepair : this.quantityTotalCatalog
    return roundDecimal(this.totalVAT + totalPrice)
  }

  get totalPriceWithoutVAT() {
    return this.selfRepair ? this.quantityTotalSelfRepair : this.quantityTotalCatalog
  }

  get finalPriceCatalog() {
    return roundDecimal(
      this.totalPriceCatalogWithVAT -
        this.totalPriceCatalogObsolescence +
        this.totalCatalogObsolescenceReturn,
    )
  }

  get finalPriceSelfRepair() {
    return roundDecimal(
      this.totalPriceSelfRepairWithVAT -
        this.totalSelfRepairObsolescence +
        this.totalSelfRepairObsolescenceReturn,
    )
  }

  get finalPriceCatalogWithoutVAT() {
    return roundDecimal(
      this.quantityTotalCatalog -
        this.totalPriceCatalogObsolescenceWithoutVAT +
        this.totalCatalogORWithoutVAT,
    )
  }

  get finalPriceSelfRepairWithoutVAT() {
    return roundDecimal(
      this.quantityTotalSelfRepair -
        this.totalPriceSelfRepairObsolescenceWithoutVAT +
        this.totalSelfRepairORWithoutVAT,
    )
  }

  get finalPrice() {
    return this.selfRepair ? this.finalPriceSelfRepair : this.finalPriceCatalog
  }

  get finalPriceWithoutVAT() {
    return this.selfRepair ? this.finalPriceSelfRepairWithoutVAT : this.finalPriceCatalogWithoutVAT
  }

  get materialPresent() {
    if (this.priceType !== 'catalog' && this.priceType !== 'custom') return false
    let present = false
    this.items.forEach(item => {
      if (item.piActive && item.materials && item.materials.length > 0) present = true
    })
    return present
  }

  get areaPresent() {
    return this.unit === 'M2' || this.unit === 'ML'
  }

  get area() {
    if (!this.areaPresent) return null

    return isNil(this.overrideAreaSquare)
      ? parseFloat(this.areaSquare)
      : parseFloat(this.overrideAreaSquare)
  }

  get isPackageOverrode() {
    if (this.overridePrice !== null) return true
    let overrode = false
    this.items.forEach(item => {
      if (item.isItemOverrode) overrode = true
    })
    return overrode
  }

  get allowedLocalisations() {
    return this.localisations.filter(localisation => Localisations.includes(localisation))
  }

  get warningMinPrice() {
    if (this.overridePrice === null) return false
    const price = this.selfRepair ? this.originalTotalSelfRepair : this.originalTotalPriceCatalog
    return price <= this.minPrice
  }

  get changed() {
    if (this.originalData.involvedParty !== this.involvedParty) return true
    if (this.originalData.supportingDocuments.length !== this.supportingDocuments.length)
      return true

    // ALLOW AUTO SAVE FOR EASY ESTIMATION
    if (
      this.originalData.ren !== this.ren ||
      (this.originalData.companyName !== this.companyName && !isNil(this.easyEstimationData))
    )
      return true

    // DISABLE AUTO SAVE FOR THESE VARIABLE FOR VENTILATION
    if (this.originalData.ren !== this.ren || this.originalData.companyName !== this.companyName)
      return false

    return !equals(toJS(this.originalData), this.asJson)
  }

  get changedREN() {
    return this.originalData.ren !== this.ren || this.originalData.companyName !== this.companyName
  }

  get totalForInsuredWithVAT() {
    const total = roundDecimal(this.totalObsolescence - this.totalObsolescenceReturn)
    return total < 0 ? 0 : total
  }

  get asJson() {
    return {
      id: this.id,
      _id: this._id,
      cde: this.cde,
      score: this.score,
      description: this.description,
      label: this.label,
      localisations: this.localisations,
      localisation: this.localisation,
      lifetime: this.lifetime,
      unit: this.unit,
      packageType: this.packageType,
      roomType: toJS(this.roomType),
      selfRepair: this.selfRepair,
      qualityType: this.qualityType,
      priceType: this.priceType,
      priceListPrice: this.priceListPrice,
      priceList: this.priceList,
      priceListLabel: this.priceListLabel,
      overridePrice: this.overridePrice,
      overrideAreaSquare: this.overrideAreaSquare,
      age: this.age,
      correction: this.correction,
      note: this.note,
      minPrice: this.minPrice,
      difficulty: this.difficulty,
      items: this.items.map(item => item.asJson),
      supportingDocuments: this.supportingDocuments.map(sd => sd),
      quantity: this.quantity,
      areaSquare: this.areaSquare,
      vat: this.vat,
      obsolescenceReturn: this.obsolescenceReturn,
      ren: this.ren,
      companyName: this.companyName,
      location: this.location,
      involvedParty: this.involvedParty,
      groupId: this.groupId,
      // EASY ESTIMATION
      easyEstimationData: this.easyEstimationData,
      // TOTALS
      totals: {
        catalog: {
          totalObsolescence: this.totalPriceCatalogObsolescence,
          totalWithoutVAT: this.quantityOriginalTotalPriceCatalog,
          totalVAT: this.totalCatalogVAT,
          totalWithVAT: this.totalPriceCatalogWithVAT,
          totalOR: this.totalCatalogObsolescenceReturn,
          finalPrice: this.finalPriceCatalog,
          // new computations
          totalObsolescenceWithoutVAT: this.totalPriceCatalogObsolescenceWithoutVAT,
          totalORWithoutVAT: this.totalCatalogORWithoutVAT,
          finalPriceWithoutVAT: this.finalPriceCatalogWithoutVAT,
          totalCustomCalculatedWithoutVAT: this.quantityOriginalTotalPriceCatalog,
        },
        selfRepair: {
          totalObsolescence: this.totalSelfRepairObsolescence,
          totalWithoutVAT: this.quantityOriginalTotalPriceSelfRepair,
          totalVAT: this.totalSelfRepairVAT,
          totalWithVAT: this.totalPriceSelfRepairWithVAT,
          totalOR: this.totalSelfRepairObsolescenceReturn,
          finalPrice: this.finalPriceSelfRepair,
          // new computations
          totalObsolescenceWithoutVAT: this.totalPriceSelfRepairObsolescenceWithoutVAT,
          totalORWithoutVAT: this.totalSelfRepairORWithoutVAT,
          finalPriceWithoutVAT: this.finalPriceSelfRepairWithoutVAT,
          totalCustomCalculatedWithoutVAT: this.quantityOriginalTotalPriceSelfRepair,
        },
      },
    }
  }

  constructor({ data, fromList, packageType, roomType, priceType, groupId = null }) {
    this.cde = data.cde || null
    this.description = data.description
    this.label = data.label
    this.localisations = data.localisations || []
    this.lifetime = data.lifetime || null
    this.unit = data.unit
    this.minPrice = data.minPrice || -1
    this.difficulty = data.difficulty || 1
    this.supportingDocuments = data.supportingDocuments || []
    this.quantity = data.quantity || 1
    this.score = data.score || 1
    this.groupId = data.groupId || groupId

    this.id = data.id || uid(15)
    this._id = data._id
    this.fromList = fromList ? fromList : data.fromList
    this.packageType = packageType ? packageType : data.packageType
    this.roomType = roomType ? roomType : data.roomType
    this.selfRepair = data.selfRepair ? data.selfRepair : false
    this.qualityType = data.qualityType ? data.qualityType : 'medium'
    this.priceType = priceType ? priceType : data.priceType
    this.priceListPrice = this.priceType !== 'catalog' ? data.priceListPrice : null
    this.priceList = data.priceList || null
    this.priceListLabel = data.priceListLabel || null
    if (data.overridePrice) this._setOverridePrice(data.overridePrice)
    this.overrideAreaSquare = isNil(data.overrideAreaSquare) ? null : data.overrideAreaSquare
    this.age = isNil(data.age) ? 0 : data.age
    this.correction = isNil(data.correction) ? 0 : data.correction
    this.note = data.note || ''
    this.vat = isNil(data.vat) ? (CartStore.isTaxSystemHT ? 0 : 10) : data.vat
    this.obsolescenceReturn = isNil(data.obsolescenceReturn) ? 25 : data.obsolescenceReturn
    this.localisation = data.localisation || null
    if (this.localisation === null && this.localisations.length === 1)
      this.localisation = this.localisations[0]
    if (data.ren === null) {
      this.ren = false
    } else {
      if (data.ren === undefined) this.ren = true
      else this.ren = data.ren
    }
    this.companyName = data.companyName || ''
    this.areaSquare =
      data.areaSquare || roundDecimal(this.roomType.areaSize * ((data.areaPercent || 100) / 100))
    // EASY ESTIMATION
    this.easyEstimationData = data.easyEstimationData || null

    this.items = isNil(data.items)
      ? []
      : data.items
          .map(
            item =>
              new Item({
                data: item,
                qualityType: this.qualityType,
                packageUnit: this.unit,
                packageDifficulty: this.difficulty,
                areaSquare: isNil(this.overrideAreaSquare)
                  ? this.areaSquare
                  : this.overrideAreaSquare,
                packageIsSelfRepair: this.selfRepair,
              }),
          )
          .sort((a, b) => a.order - b.order)
    this.location = data.location || 'regular'
    this.involvedParty =
      data.involvedParty === 'notVentilated' || isNil(data.involvedParty)
        ? null
        : data.involvedParty

    // IRSI 2 RULES
    // if (MissionStore.isIRSI2 && this.involvedParty === MissionStore.insuredInformationId) {
    //   this.ren = true
    // } else if (MissionStore.isIRSI2 && this.involvedParty !== MissionStore.insuredInformationId) {
    //   this.ren = false
    //   this.companyName = ''
    // }

    this.originalData = this.asJson
    this.setReactions()
  }

  setReactions() {
    this.changeQualityTypeHandler = reaction(
      () => this.qualityType,
      qualityType => {
        this.items.forEach(item => item.setProperty('qualityType', qualityType))
      },
    )

    this.changeSelfRepairHandler = reaction(
      () => this.selfRepair,
      selfRepair => {
        this.items.forEach(item => item.setProperty('selfRepair', selfRepair))
      },
    )

    this.changeOverridePriceHandler = reaction(
      () => this.overridePrice,
      overridePrice => {
        if (overridePrice !== null)
          this.items.forEach(item => {
            item.setProperty('overrode', true)
            item.setProperty('overrodeQualification', true)
          })
        else {
          this.items.forEach(item => {
            item.setProperty('overrode', false)
            item.setProperty('overrodeQualification', false)
          })
        }
      },
    )

    this.changeOverrideAreaSquareHandler = reaction(
      () => this.overrideAreaSquare,
      overrideAreaSquare => {
        this.items.forEach(item => item.setProperty('areaSquare', overrideAreaSquare))
      },
    )
  }

  disposeReactions() {
    this.changeQualityTypeHandler()
    this.changeSelfRepairHandler()
    this.changeOverridePriceHandler()
    this.changeOverrideAreaSquareHandler()
  }

  _setOverridePrice(value) {
    value = parseFloat(value)
    if (isNaN(value)) {
      value = null
    }

    this.overridePrice = value
  }

  clearPiGroupPiActivated(activateItem) {
    if (activateItem.piGroup !== 0) {
      const itemsFiltered = this.items.filter(
        item => item.piGroup === activateItem.piGroup && item.piActive === true,
      )
      itemsFiltered.forEach(itemFiltered => itemFiltered.setProperty('piActive', false))
    }
  }

  setProperty(key, value) {
    if (key === 'age' && value !== null) value = parseInt(value, 10)
    else if (key === 'correction' && value !== null) value = parseInt(value, 10)
    else if (key === 'obsolescenceReturn' && value !== null) value = parseInt(value, 10)
    else if (key === 'overridePrice') {
      this._setOverridePrice(value)
      return
    }
    // else if (
    //   key === 'involvedParty' &&
    //   MissionStore.isIRSI2 &&
    //   MissionStore.insuredInformationId === value
    // ) {
    //   this.ren = true
    // } else if (
    //   key === 'involvedParty' &&
    //   MissionStore.isIRSI2 &&
    //   MissionStore.insuredInformationId !== value
    // ) {
    //   this.ren = false
    //   this.companyName = ''
    // }

    if (key === 'involvedParty' && value === '') {
      value = null
    }

    this[key] = value
  }

  setRoomTypeLabel(value) {
    if (value === null) {
      this.roomType.override = {}
    } else if (this.roomType.override && value === this.roomType.label) {
      this.roomType.override = {}
    } else {
      this.roomType.override = {}
      this.roomType.override.label = value
      this.roomType.override.key = this.roomType.key + '_' + value
      this.roomType.override.display = value + ' (' + this.roomType.label + ')'
    }
  }

  addSupportingDocument(id) {
    this.supportingDocuments.push(id)
  }

  setOriginalData() {
    this.originalData = this.asJson
  }

  resetOriginalREN() {
    this.ren = this.originalData.ren
    this.companyName = this.originalData.companyName
  }
}

const DecoratedPackage = decorate(Package, {
  label: observable,
  description: observable,
  supportingDocuments: observable,
  qualityType: observable,
  priceType: observable,
  fromList: observable,
  packageType: observable,
  roomType: observable,
  localisation: observable,
  selfRepair: observable,
  age: observable,
  correction: observable,
  note: observable,
  overridePrice: observable,
  areaSquare: observable,
  overrideAreaSquare: observable,
  quantity: observable,
  vat: observable,
  obsolescenceReturn: observable,
  ren: observable,
  companyName: observable,
  originalData: observable,
  involvedParty: observable,

  clearPiGroupPiActivated: action,
  setProperty: action.bound,
  setRoomTypeLabel: action,
  addSupportingDocument: action,
  setOriginalData: action,
  resetOriginalREN: action,

  originalTotalPriceCatalog: computed,
  originalTotalPriceSelfRepair: computed,
  originalTotalPrice: computed,
  quantityOriginalTotalPriceCatalog: computed,
  quantityOriginalTotalPriceSelfRepair: computed,
  quantityOriginalTotalPrice: computed,
  quantityTotalCatalog: computed,
  quantityTotalSelfRepair: computed,
  quantityTotal: computed,
  obsolescence: computed,
  totalPriceCatalogObsolescence: computed,
  totalSelfRepairObsolescence: computed,
  totalObsolescence: computed,
  totalCatalogObsolescenceReturn: computed,
  totalSelfRepairObsolescenceReturn: computed,
  totalObsolescenceReturn: computed,
  totalCatalogVAT: computed,
  totalSelfRepairVAT: computed,
  totalVAT: computed,
  totalPriceCatalogWithVAT: computed,
  totalPriceSelfRepairWithVAT: computed,
  totalPriceVAT: computed,
  finalPriceCatalog: computed,
  finalPriceSelfRepair: computed,
  finalPrice: computed,

  // new computations
  totalPriceCatalogObsolescenceWithoutVAT: computed,
  totalPriceSelfRepairObsolescenceWithoutVAT: computed,
  totalPriceWithoutVAT: computed,
  totalObsolescenceWithoutVAT: computed,
  totalCatalogORWithoutVAT: computed,
  totalSelfRepairORWithoutVAT: computed,
  finalPriceCatalogWithoutVAT: computed,
  finalPriceSelfRepairWithoutVAT: computed,
  finalPriceWithoutVAT: computed,
  totalForInsuredWithVAT: computed,

  materialPresent: computed,
  areaPresent: computed,
  area: computed,
  isPackageOverrode: computed,
  allowedLocalisations: computed,
  warningMinPrice: computed,
  changed: computed,
  asJson: computed,
  changedREN: computed,
})

export default DecoratedPackage
