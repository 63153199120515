import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SmartLink from 'components/SmartLink/SmartLink'
import GenerateReportLink from './GenerateReportLink'

const MissionMobile = ({
  PrejudiceStore: { payloads, ready },
  UiCtrl: { toggleSideNav },
  MissionStore: { isHorsDarva, isIRSI, isASP, pvRequired, isWorkCFA },
  MessagingStore: { notifications },
  UserStore: { customer },
}) => {
  const onClick = () => toggleSideNav(false)
  const { t } = useTranslation()
  const { id } = useParams()
  const hasExpertiseSimplify = customer.hasContract('ES')

  return (
    <div className="side-nav">
      <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.title')}</h5>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/mission/${id}/information`}
        onClick={onClick}
      >
        {t('mission.sideNav.information')}
      </NavLink>
      {isWorkCFA && (
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/mission/${id}/policy`}
          onClick={onClick}
        >
          {t('mission.sideNav.policy')}
        </NavLink>
      )}
      {isWorkCFA && (
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/mission/${id}/claim`}
          onClick={onClick}
        >
          {t('mission.sideNav.claim')}
        </NavLink>
      )}

      <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleAnnexe')}</h5>

      {!hasExpertiseSimplify && (
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/mission/${id}/partyInvolved`}
          onClick={onClick}
        >
          {t('mission.sideNav.partyInvolved')}
        </NavLink>
      )}
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/mission/${id}/supportingDocuments`}
        onClick={onClick}
      >
        {t('mission.sideNav.supportingDocument')}
      </NavLink>
      {!isHorsDarva && (
        <Fragment>
          <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleEvaluation')}</h5>

          {!hasExpertiseSimplify && (
            <SmartLink page="riskConformity">{t('mission.sideNav.riskConformity')}</SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="causesAndCircumstances">
              {t('mission.sideNav.causesAndCircumstances')}
            </SmartLink>
          )}
          {!hasExpertiseSimplify && (
            <SmartLink page="damageDescription">{t('mission.sideNav.damageDescription')}</SmartLink>
          )}
          {!hasExpertiseSimplify && isIRSI && (
            <SmartLink page="irsi">{t('mission.sideNav.irsi')}</SmartLink>
          )}
          <SmartLink page="calculation">{t('mission.sideNav.calculation')}</SmartLink>
          {!hasExpertiseSimplify && (
            <SmartLink page="ventilation">{t('mission.sideNav.ventilationREN')}</SmartLink>
          )}
          {!hasExpertiseSimplify && !isASP && ready && !!payloads.length && (
            <SmartLink page="prejudice">{t('mission.sideNav.prejudice')}</SmartLink>
          )}
          <SmartLink page="conclusion">{t('mission.sideNav.conclusion')}</SmartLink>
          {!hasExpertiseSimplify && pvRequired && (
            <SmartLink page="pv">{t('mission.sideNav.pv')}</SmartLink>
          )}
          <SmartLink page="rates">{t('mission.sideNav.rates')}</SmartLink>

          <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleReport')}</h5>

          <GenerateReportLink />
        </Fragment>
      )}
      <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleCommunication')}</h5>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/mission/${id}/messaging`}
        onClick={onClick}
      >
        {t('messaging.title')}

        {notifications > 0 && <span className="badge badge-danger ml-1">{notifications}</span>}
      </NavLink>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/mission/${id}/events`}
        onClick={onClick}
      >
        {t('events.title')}
      </NavLink>
    </div>
  )
}

export default inject('PrejudiceStore', 'UiCtrl', 'MissionStore', 'MessagingStore', 'UserStore')(
  observer(MissionMobile),
)
